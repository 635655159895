import React from "react";
import Reveal from "react-reveal/Reveal";

const About = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <Reveal effect="fadeInLeft">
                  <div className="image">
                    <img src="/img/placeholders/1-1.jpg" alt="placeholder" />
                    <div
                      className="main"
                      style={{
                        backgroundImage: `url(${
                          process.env.PUBLIC_URL + "img/about/5.jpg"
                        })`,
                      }}
                    ></div>
                  </div>
                </Reveal>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <Reveal effect="fadeInLeft">
                  <div className="shane_tm_title">
                    <span>About Me</span>
                    <h3>Assistant Professor & Deputy Director</h3>
                  </div>
                  <div className="text">
                    <p>
                    I am a master at failure and a cultivator of happiness, this I have achieved with both my resilience and grittiness. Now I'm pursuing more to be emotionally intelligent. This helps immensely in my professional life as a Deputy Director of HRDI and Assistant Professor of Daffodil International University.
                    </p>
                    <br />
                    <a href="https://www.facebook.com/Ejaj.Ur.Rahaman.Shajal">
         <img src="facebook.png" alt=""  srcset="" />
         
      </a>&nbsp;
      <a href="https://www.linkedin.com/in/ejajur/">
         <img src="linkedin.png" alt=""  srcset="" />
         
      </a>&nbsp;
      <a href="https://www.instagram.com/eshajal/">
         <img src="instagram.png" alt=""  srcset="" />
         
      </a>
                  
                    
                  </div>
                  <div className="shane_tm_button">
                    <a href="https://docs.google.com/document/d/1p28dJtgMi9_ToUCNsS0ddf_B5rHn9RMb8C5TcCe0EF0/edit?usp=sharing" download>
                      Download CV
                    </a>
                  </div>
                </Reveal>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;
