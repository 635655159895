import React, { useState } from "react";
import Modal from "react-modal";
import Social from "../Social";
import Reveal from "react-reveal/Reveal";

Modal.setAppElement("#root");

const NewsTwo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  return (
    <div className="shane_tm_section" id="news">
      <div className="shane_tm_news">
        <div className="container">
          <div className="shane_tm_title">
            <span>News</span>
            <h3>Latest News</h3>
          </div>
          {/* End shane_tm_title */}
          <div className="news_list">
            <ul>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalOne}>
                      <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/4.png"
                          })`,
                        }}
                      ></div>
                    </div>
                    {/* End image */}

                    <div className="details">
                      <h3 className="title" onClick={toggleModalOne}>
                     Selected as an International facilitator
                      </h3>
                      <p className="date">
                        By <a href="https://www.facebook.com/ProjectDorm">Project Dorm</a> <span>07 March 2021</span>
                      </p>
                    </div>
                    {/* End details */}

                    {/* START MODAL */}
                    <Modal
                      isOpen={isOpen}
                      onRequestClose={toggleModalOne}
                      contentLabel="My dialog"
                      className="custom-modal"
                      overlayClassName="custom-overlay"
                      closeTimeoutMS={500}
                    >
                      <div className="shane_tm_modalbox_news">
                        <button
                          className="close-modal"
                          onClick={toggleModalOne}
                        >
                          <img src="/img/svg/cancel.svg" alt="close icon" />
                        </button>
                        {/* End close icon */}
                        <div className="box_inner">
                          <div className="description_wrap scrollable">
                            <div className="image">
                              <img
                                src="/img/placeholders/4-3.jpg"
                                alt="thumb"
                              />
                              <div
                                className="main"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.PUBLIC_URL + "img/news/4.png"
                                  })`,
                                }}
                              ></div>
                            </div>
                            {/* End image */}
                            <div className="details">
                              <h3 className="title">
                              Selected as an International Facilator
                              </h3>
                              <p className="date">
                                By <a href="https://www.facebook.com/ProjectDorm">Project Dorm</a>{" "}
                                <span>07 March 2021</span>
                              </p>
                            </div>
                            {/* End details */}
                            <div className="description">
                              <p>
                              I heard somewhere that, “Teaching is the profession that teaches all of the other professions.” This word had a huge impact on me. Since then, I've fantasized about being an INSTRUCTOR. My desire has gradually become true when I began teaching at Daffodil International University. When “Project Dorm” chose to recruit me as one of it’s Trainer, this dream became even more real. 

“Project Dorm”- is a platform for various learning and recreational education for Singaporean immigrants. So, I have been invited to provide online training to Migrant Workers residing in Singapore via the Facebook Page Project Dorm. The training sessions were LIVE streamed onto the Facebook Page via the Online software ‘Live Reacting’. The software subscription fee will be borne by the Company. 
The training will be conducted in a mix of Bengali & English. The trainer will be engaged for 15 sessions of maximum 60 minutes each. The episodes will begin on  Monday, 22nd February 2021 and will continue for 15 weeks, every Monday. The trainer is required to commit to completing all 15 sessions. The session will begin at 7:00 PM (Singapore Time) and should end at 7:50 PM (Singapore Time).

                              </p>
                              <blockquote>
                              Working as an instructor here has given me the opportunity to develop myself, and I believe I have been able to transfer some of my knowledge to a few more people. This was one of my biggest achievements. Each instructor has a responsibility to establish and maintain a citizen inclusive and stimulating learning environment. Also when a teacher teaches not only does he share his knowledge with his students but a sweet friendly relationship is formed between them which develops them a lot more towards relationship management and other aspects.The basic obligation of both the instructor and the student is to respect the instructional setting as a place of civic modest conversation. So as an instructor there are many more responsibilities than just instructing someone which I understood very well when I worked as an instructor in “Project Dorm'' and got a chance to develop myself more by enhancing my knowledge and skills stes. So this opportunity was precious to me.

                              </blockquote>
                              <p>
                              It is a teacher's duty to provide every student with relatable and relevant information about the course in a timely manner. I have achieved this through the work of generations.
The feedback I received from students when I took courses on one subject as an instructor helped me to be more refined.

Just as every student is different, so are their thoughts, their mindsets and their lifestyles.  So I also got the training to work as an instructor here which helped me to make someone understand a subject rather quickly.
Also I can now maintain an appropriate learning environment, maintain a classroom environment and maintain a very neat schedule for various academic tasks better than before.  And all of this has been possible since I started working here as an instructor.  Because we all know- “Practice makes a man perfect”.

                              </p>
                            </div>
                            {/* End description */}
                            <div className="news_share">
                              <Social />
                              {/* End social share */}
                            </div>
                            {/* End news share */}
                          </div>
                        </div>
                        {/* End box inner */}
                      </div>
                      {/* End modal box news */}
                    </Modal>
                    {/* End modal */}
                  </div>
                </li>
                {/* End single blog */}
              </Reveal>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalTwo}>
                      <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/9.jpg"
                          })`,
                        }}
                      ></div>
                    </div>
                    {/* End image */}

                    <div className="details">
                      <h3 className="title" onClick={toggleModalTwo}>
                      Proudly hosting TEDxDaffodilU                       </h3>
                      <p className="date">
                        By <a href="https://www.facebook.com/asifuzzamaneee">Asifuzzaman Asif</a>{" "}
                        <span>17 July 2021</span>
                      </p>
                    </div>
                    {/* End details */}

                    <Modal
                      isOpen={isOpen2}
                      onRequestClose={toggleModalTwo}
                      contentLabel="My dialog"
                      className="custom-modal"
                      overlayClassName="custom-overlay"
                      closeTimeoutMS={500}
                    >
                      <div className="shane_tm_modalbox_news">
                        <button
                          className="close-modal"
                          onClick={toggleModalTwo}
                        >
                          <img src="/img/svg/cancel.svg" alt="close icon" />
                        </button>
                        {/* End close modal */}
                        <div className="box_inner">
                          <div className="description_wrap scrollable">
                            <div className="image">
                              <img
                                src="/img/placeholders/4-3.jpg"
                                alt="thumb"
                              />
                              <div
                                className="main"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.PUBLIC_URL + "img/news/9.jpg"
                                  })`,
                                }}
                              ></div>
                            </div>
                            {/* End image */}
                            <div className="details">
                              <h3 className="title">
                              Proudly hosting TEDxDaffodilU 
                              </h3>
                              <p className="date">
                                By <a href="https://www.facebook.com/asifuzzamaneee">Asifuzzaman</a>{" "}
                                <span>17 July 2021</span>
                              </p>
                            </div>
                            {/* End details */}
                            <div className="description">
                              <p>
                              Imagine a group of brilliant speakers present at an event and you have the whole responsibility to manage the event: the sprakers, volunteers and internal organizers. What is the requirement you need? 

ARE YOU CONFIDENT enough to host that event?  

Can they rely on you?

For that, you should have some essential skills. Such as Growth Mindset, Leadership, Time Management and more. I always emphasize that I am a master of failure and that I can accept mistakes as lessons, which is the core of a Growth Mindset. From that point of view, I think I am confident enough to manage all these brilliant speakers. 
In this world, there are many people who want to achieve goals with dedication but they sometimes deter due to insufficiency of encouragement and be a team. Well I firmly believe I can encourage them, and become A TEAM with them. A team where everyone has a thirst to do amazing things, perform their skillset as a true Champion that they are. And TEDx was the perfect challenge for them. 

TEDx is a platform, where extraordinary speakers share their creativity and thought-provoking ideas. This event brings abundant opportunities for all people, especially the organizers. Nagging this event exposes them to certain information, experiences and increases their soft skills that helps them in the long run.
                              </p>
                              <blockquote>
                              As a lead organizer,I have had some amazing experiences in TEDX.So not only as an organizer but also as a host I can manage any event environment perfectly. A perfect presentation can make an environment more than enjoyable and the audience are more attuned with the event. Hosting the TedX has helped me increase my ability to become a more confident host and now I can host events even in shorter notice, and maintain my audience's focus better than ever.
                              </blockquote>
                              <p>
                              When I worked on this platform, I instructed my team to follow all rules and guidelines given by the partner organization and it actually became useful for the team to abide by them, especially putting aside the age old habits. My team and I became more punctual, learned some new etiquettes and more. TedX had another contribution for myself and my team that's to produce attractive content. And thanks to all my experience and knowledge I now know which way to lead a team and Organize, Host and produce an amazing event. 

So it was a great opportunity to express my potential through the TEDx event. And my team had the opportunity just as well.
                              </p>
                            </div>
                            {/* End description */}
                            <div className="news_share">
                              <Social />
                              {/* End social share */}
                            </div>
                            {/* End news share */}
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {/* End modal */}
                  </div>
                  {/* End list inner */}
                </li>
                {/* End single blog */}
              </Reveal>
              <Reveal effect="fadeInLeft">
                <li>
                  <div className="list_inner">
                    <div className="image" onClick={toggleModalThree}>
                      <img src="/img/placeholders/4-3.jpg" alt="thumb" />
                      <div
                        className="main"
                        style={{
                          backgroundImage: `url(${
                            process.env.PUBLIC_URL + "img/news/8.png"
                          })`,
                        }}
                      ></div>
                    </div>
                    {/* END IMAGE */}

                    <div className="details">
                      <h3 className="title" onClick={toggleModalThree}>
                        Youth Trainer of BYSLP
                      </h3>
                      <p className="date">
                        By <a href="https://www.usaid.gov/bd">US Aid</a>{" "}
                        <span>02 July 2019</span>
                      </p>
                    </div>
                    {/* END DETAILS */}

                    {/* START MODAL */}
                    <Modal
                      isOpen={isOpen3}
                      onRequestClose={toggleModalThree}
                      contentLabel="My dialog"
                      className="custom-modal"
                      overlayClassName="custom-overlay"
                      closeTimeoutMS={500}
                    >
                      <div className="shane_tm_modalbox_news">
                        <button
                          className="close-modal"
                          onClick={toggleModalThree}
                        >
                          <img src="/img/svg/cancel.svg" alt="close icon" />
                        </button>
                        {/* END CLOSE MODAL */}
                        <div className="box_inner">
                          <div className="description_wrap scrollable">
                            <div className="image">
                              <img
                                src="/img/placeholders/4-3.png"
                                alt="thumb"
                              />
                              <div
                                className="main"
                                style={{
                                  backgroundImage: `url(${
                                    process.env.PUBLIC_URL + "img/news/8.png"
                                  })`,
                                }}
                              ></div>
                            </div>
                            {/* END IMAGE */}
                            <div className="details">
                              <h3 className="title">
                                Tolerance Trainer of Youths.
                              </h3>
                              <p className="date">
                                By <a href="https://www.usaid.gov/bd">US Aid</a>{" "}
                                <span>02 July 2019</span>
                              </p>
                            </div>
                            {/* END DETAILS */}
                            <div className="description">
                              <p>
                              Youth of any nation are the main power source and they need to be aware and conscious about the society, societal behaviour and norms to change the society, the country towards betterment. 

Bangladesh Youth Social Leadership Program (BYSLP) was a getaway of opportunity for this youth development and for the trainers as well. Through this program I had the chance to become a trainer for two amazing groups. 

"Bangladesh Youth Social Leadership Training" In collaboration with New York University (NYU) & NORC of Chicago University, supported by USAID’s Obirodh: Road to Tolerance program, Rupantar (a non-government organization) provided "Youth Social Leadership Training" training  for the students of Daffodil International University. This was a free of cost program for the students who also earned the certificate from New York University (NYU) and NORC of Chicago University!!

This program provided the students with leadership training, knowledge about important social issues in Bangladesh, and an opportunity to meet and get to know like-minded peers. The activities performed by the students were part of a study conducted by research based New York University. During the ongoing program students were asked to:
1) Attend about 14 two-hour class sessions led by an instructor
2) Complete periodic assignments in class, remotely on a mobile phone computer, or in a computer lab.
 3) Complete periodic electronic surveys and group discussions.

                              </p>
                              <blockquote>
                              Throughout taking sessions and activities I got the chance to explore my own beliefs, values and the scenario and societal norms around me. And what I am doing as a member of the society, how I am contributing for the betterment of my society and my students. The program had emphasized on developing the students leadership qualities and critical thinking ability. They went through several trials and practical tasks.

Through conducting sessions and assessing tasks I got to learn more about our students' leadership behaviors for social applications.  My skill sets, connecting with my students and the ability to empathize with them and share a similar mindset was developed through this session period. 

                              </blockquote>
                              <p>
                              An educator is just as much responsible as a student's family for that student to conduct a conscious and meaningful life. They need to be guided and modeled how to lead a life maintaining harmonious relationships with family, friends and even with one's own self. This leadership program enabled me to convey all this to my students and helped me realize the need to be more empathic, tolerant and think deeply about the society and my abilities to make an impact in it.  

This was one of a kind program and it made me realize the ability of an educator and how I can make my students be a nation developer and how I can become not just a better teacher but a teacher leader. 

                              </p>
                            </div>
                            {/* END DESCRIPTION */}
                            <div className="news_share">
                              <Social />
                              {/* END SOCIAL SHARE */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  {/* END LIST INNER */}
                </li>
              </Reveal>
              {/* End single blog */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsTwo;
