import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialTwo() {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <ul>
      <Slider {...settings}>
        <li className="item">
          <div className="texts">
            <p>
            A man of different skills. I always admire his approach to solve a problem with calmness and a nice smile. He has a very good communication skills. The way he manage a team and different projects is really amazing. As a parent he can be a role model. I always pray for his success and good healthy life.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/1.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Masud Parvaj Mithu</span>
                </h3>
                <h3 className="job">
                  <span>Coach, Teacher & Trainer</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}

        <li className="item">
          <div className="texts">
            <p>
            I was lucky enough to have a supervisor like him in the very first job of my life. For me, he is a big inspiration, he always cares about the people around him. I have learned a lot of things from him. I learned that a good leader can take any team and make it succeed. He is the best leader that I have ever met. I also learned time management, maintaining commitment, leadership, dealing with people, growth mindset, and self awareness from him. 

I don’t think I can ever thank him enough or fully demonstrate how valuable his leadership and friendship are to me.

            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/2.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Md. Asifuzzaman</span>
                </h3>
                <h3 className="job">
                  <span>Employee, Daffodil International University</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}

        <li className="item">
          <div className="texts">
            <p>
            He is a man of dedication with good communication skills .Rather than focusing on teaching, as a teacher he spends more time and effort to inspire student’s interest in different skills and teach them how to appreciate the creativity and quality behind every single skill.  I am continuously impressed by how well he knows the students as a teacher with good soul. His style of teaching is phenomenal. As a team leader he is one who knows the way, goes the way and shows the way. I generally petition almighty for his strength, endurance to perform his all task.
            </p>
            <div className="author">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: `url(${
                      process.env.PUBLIC_URL + "img/about/3.jpg"
                    })`,
                  }}
                ></div>
              </div>
              <div className="short">
                <h3 className="author">
                  <span>Md Sajib Khan</span>
                </h3>
                <h3 className="job">
                  <span>Fellow & Trainer of Daffodil International University</span>
                </h3>
              </div>
            </div>
          </div>
        </li>
        {/* End single slide item */}
      </Slider>
    </ul>
  );
}
