import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Reveal from "react-reveal/Reveal";

const PortfolioTwo = () => {
  return (
    <div className="shane_tm_section" id="portfolio">
      <SimpleReactLightbox>
        <div className="shane_tm_portfolio">
          <div className="container">
            <div className="positon-relative">
              <div className="shane_tm_title">
                <div className="title_flex">
                  <div className="left">
                    <span>Portfolio</span>
                    <h3>Creative Portfolio</h3>
                  </div>
                </div>
              </div>
              {/* End shane_tm_title */}
              <div className="portfolio_filter">
                <Tabs>
                  <TabList>
                    <Tab>All</Tab>
                    <Tab>Teachers Trainer</Tab>
                    <Tab>Coach</Tab>
                    <Tab>Professor</Tab>
                  </TabList>
                  {/* End tablist */}
                  <div className="portfolio_list">
                    <SRLWrapper>
                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/1.png">
                                    <img
                                      src="/img/portfolio/1.png"
                                      alt=" Dedicatedly giving training to prepare the future nation builders who will spread the light of knowledge and contribute to develop the future generation. "
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Technical Teachers Training College (TTTC)</h3>
                                  <span>Teachers Trainer</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/2.png">
                                    <img
                                      src="/img/portfolio/2.png"
                                      alt=" Had the experience as Visiting professor in Philippine LPU University and broadened my skill sets.
                                      "
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Phillipin LPU University</h3>
                                  <span> Visiting Professor </span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/3.jpg">
                                    <img
                                      src="/img/portfolio/3.jpg"
                                      alt=" Proudly coaching to a subject from which not only we can learn, but also get the life lessons which will make you realize what is the ideal living way and the transformation of life. 
                                      "
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Art of Living</h3>
                                  <span>Coach</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/4.jpg">
                                    <img
                                      src="/img/portfolio/4.jpg"
                                      alt="Proud fellow of teach for Bangladesh family and was a member of teach for Bangladesh for 1 year.
                                      "
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Teach for Bangladesh</h3>
                                  <span>Fellow</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/5.jpg">
                                    <img
                                      src="/img/portfolio/5.jpg"
                                      alt="Trained the teacher leaders in Bangladesh University of Professionals."
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Bangladesh University of Professionals </h3>
                                  <span>LMS Trainer</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}

                          <li>
                            <Reveal effect="fadeIn">
                              <div className="inner">
                                <div className="entry shane_tm_portfolio_animation_wrap">
                                  <a href="/img/portfolio/16.jpg">
                                    <img
                                      src="/img/portfolio/16.jpg"
                                      alt="Trained the youth community, who will be the leader of future, in Prothom Alo Bondhusova.
                                      "
                                    />
                                  </a>
                                </div>
                                <div className="mobile_title">
                                  <h3>Prothom Alo Bondhusova</h3>
                                  <span>Youth Trainer</span>
                                </div>
                              </div>
                            </Reveal>
                          </li>
                          {/* End single image block */}
                        </ul>
                        {/* End portfolio list */}
                      </TabPanel>
                      {/* END ALL PORTFOLIO GALLERY */}

                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <div className="inner">
                              <div className="entry shane_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/13.jpg">
                                  <img
                                    src="/img/portfolio/13.png"
                                    alt="Teachers Trainer"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Technical Teachers Training College (TTTC)</h3>
                                <span>Teachers Trainer</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}

                          <li>
                            <div className="inner">
                              <div className="entry shane_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/14.jpg">
                                  <img
                                    src="/img/portfolio/14.jpg"
                                    alt="Teachers Trainer"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Bangladesh University of Professionals</h3>
                                <span>LMS Teachers Trainer</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}
                        </ul>
                        {/* End portfolio list */}
                      </TabPanel>

                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <div className="inner">
                              <div className="entry shane_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/24.jpg">
                                  <img
                                    src="/img/portfolio/24.jpg"
                                    alt="Teachers Trainer"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3> Art of Living</h3>
                                <span>Coach</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}

                          <li>
                            <div className="inner">
                              <div className="entry shane_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/15.jpg">
                                  <img
                                    src="/img/portfolio/15.jpg"
                                    alt="Coach"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Teach for Bangladesh</h3>
                                <span>Coach</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        {/* End single image block */}
                      </TabPanel>

                      <TabPanel>
                        <ul className="gallery_zoom">
                          <li>
                            <div className="inner">
                              <div className="entry shane_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/11.jpg">
                                  <img
                                    src="/img/portfolio/11.jpg"
                                    alt="Professor"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Phillipin LPU University</h3>
                                <span>Visiting Professor</span>
                              </div>
                            </div>
                          </li>
                          {/* End single image block */}
                          

                          <li>
                            <div className="inner">
                              <div className="entry shane_tm_portfolio_animation_wrap">
                                <a href="/img/portfolio/16.jpg">
                                  <img
                                    src="/img/portfolio/16.jpg"
                                    alt="Convener"
                                  />
                                </a>
                              </div>
                              <div className="mobile_title">
                                <h3>Prothom Alo Bondhusova</h3>
                                <span>Convener</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                        
                        {/* End single image block */}
                      </TabPanel>
                    </SRLWrapper>
                    {/* End tabpanel */}
                  </div>
                  {/* End list wrapper */}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </SimpleReactLightbox>
    </div>
  );
};

export default PortfolioTwo;
